<template>
    <div>
        <svg width="100%" height="100%" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.29971 2.77051C6.29971 1.4387 5.11019 0.358734 3.64331 0.358734C2.17643 0.358734 0.986903 1.4387 0.986903 2.77051C0.986903 2.77051 0.943937 4.79573 2.76826 7.04969H3.64331H4.51835C6.34268 4.79573 6.29971 2.77051 6.29971 2.77051Z"
                  fill="#C1C1C6"/>
            <path d="M6.29777 2.72974C6.27824 3.16599 6.10243 4.94823 4.51835 6.90604H3.64331H2.76826C1.18419 4.94823 1.0084 3.16599 0.98887 2.72974C0.98887 2.74392 0.986903 2.75633 0.986903 2.77052C0.986903 2.77052 0.943937 4.79573 2.76826 7.0497H3.64331H4.51835C6.34268 4.79573 6.29971 2.77052 6.29971 2.77052C6.29971 2.75633 6.29777 2.74392 6.29777 2.72974Z"
                  fill="#929296"/>
            <path d="M3.64329 0.502367C5.09649 0.502367 6.27431 1.55931 6.29775 2.87339C6.30166 2.806 6.29969 2.77051 6.29969 2.77051C6.29969 1.4387 5.11017 0.358734 3.64329 0.358734C2.1764 0.358734 0.986881 1.4387 0.986881 2.77051C0.986881 2.77051 0.986895 2.806 0.988848 2.87339C1.01424 1.55931 2.19203 0.502367 3.64329 0.502367Z"
                  fill="#F0F0F5"/>
            <path d="M0.986903 11.7491C0.986903 13.081 2.17643 14.1609 3.64331 14.1609C5.11019 14.1609 6.29971 13.081 6.29971 11.7491C6.29971 11.7491 6.34268 9.72395 4.51835 7.46999H3.64331H2.77023C0.943951 9.72395 0.986903 11.7491 0.986903 11.7491Z"
                  fill="#C1C1C6"/>
            <path d="M0.98887 11.79C1.0084 11.3537 1.18419 9.56966 2.76826 7.61362H3.64331H4.51835C6.10243 9.56966 6.27824 11.3537 6.29777 11.79C6.29777 11.7758 6.29971 11.7616 6.29971 11.7491C6.29971 11.7491 6.34268 9.72395 4.51835 7.46999H3.64331H2.76826C0.943937 9.72395 0.986903 11.7491 0.986903 11.7491C0.986903 11.7616 0.98887 11.7758 0.98887 11.79Z"
                  fill="#F0F0F5"/>
            <path d="M3.64329 14.0173C2.19203 14.0173 1.01424 12.9586 0.988848 11.6463C0.986895 11.7119 0.986881 11.7491 0.986881 11.7491C0.986881 13.0809 2.1764 14.1609 3.64329 14.1609C5.11017 14.1609 6.29969 13.0809 6.29969 11.7491C6.29969 11.7491 6.30166 11.7119 6.29775 11.6463C6.27431 12.9586 5.09649 14.0173 3.64329 14.0173Z"
                  fill="#929296"/>
            <path d="M2.46549 11.2491H4.82305V11.7899H2.46549V11.2491Z" fill="black"/>
            <path d="M4.82305 3.30785H3.94213V2.50805H3.34641V3.30785H2.46549V3.84872H3.34641V4.64852H3.94213V3.84872H4.82305V3.30785Z"
                  fill="black"/>
        </svg>
    </div>
</template>

<script>
    export default {
        name: 'PlusMinusAuto'
    }
</script>

<style scoped>

</style>