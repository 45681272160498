<template>
    <div>
        <svg width="100%" height="100%" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <text id="text296940-2" fill="black" xml:space="preserve" style="white-space: pre"
                  font-family="Roboto"
                  font-size="1.5" font-weight="bold" letter-spacing="0em"><tspan x="2" y="0">Auto Load</tspan></text>
            <path d="M10.0336 5.33163C10.0336 7.79309 7.83627 9.78993 5.12322 9.78993C2.41213 9.78993 0.212769 7.79309 0.212769 5.33163C0.212769 2.86841 2.41213 0.873367 5.12322 0.873367C7.83627 0.873367 10.0336 2.86841 10.0336 5.33163Z"
                  fill="#1B3978"/>
            <path d="M8.07064 5.33164C8.07064 6.80887 6.75223 8.00589 5.12323 8.00589C3.49619 8.00589 2.17773 6.80887 2.17773 5.33164C2.17773 3.85264 3.49619 2.6556 5.12323 2.6556C6.75223 2.6556 8.07064 3.85264 8.07064 5.33164Z"
                  fill="#C1C1C6"/>
            <path d="M5.12323 2.76554C6.73075 2.76554 8.0355 3.93422 8.06871 5.38662C8.06871 5.36712 8.07064 5.34937 8.07064 5.33164C8.07064 3.85264 6.75223 2.6556 5.12323 2.6556C3.49619 2.6556 2.17773 3.85264 2.17773 5.33164C2.17773 5.34937 2.1797 5.36712 2.1797 5.38662C2.2129 3.93422 3.51767 2.76554 5.12323 2.76554Z"
                  fill="#F0F0F5"/>
            <path d="M5.12323 7.93673C3.50986 7.93673 2.19923 6.75744 2.1797 5.29617C2.1797 5.30859 2.17773 5.31923 2.17773 5.33165C2.17773 6.80887 3.49619 8.0059 5.12323 8.0059C6.75223 8.0059 8.07064 6.80887 8.07064 5.33165C8.07064 5.31923 8.06871 5.30859 8.06871 5.29617C8.04918 6.75744 6.73856 7.93673 5.12323 7.93673Z"
                  fill="#929296"/>
            <path d="M5.12322 9.64274C2.43948 9.64274 0.259645 7.68671 0.216674 5.25718C0.214721 5.28201 0.214737 5.30682 0.214737 5.33165C0.214737 7.7931 2.41213 9.78994 5.12322 9.78994C7.83627 9.78994 10.0336 7.7931 10.0336 5.33165C10.0336 5.30682 10.0337 5.28201 10.0317 5.25718C9.98874 7.68671 7.80892 9.64274 5.12322 9.64274Z"
                  fill="#142957"/>
            <path d="M5.12288 0.9904C7.81444 0.9904 9.99815 2.95531 10.0333 5.39016C10.0333 5.37066 10.0333 5.35114 10.0333 5.33163C10.0333 2.86841 7.83593 0.873367 5.12288 0.873367C2.41179 0.873367 0.214394 2.86841 0.214394 5.33163C0.214394 5.35114 0.214394 5.37066 0.214394 5.39016C0.249552 2.95531 2.43327 0.9904 5.12288 0.9904Z"
                  fill="#244CA1"/>
        </svg>
    </div>
</template>

<script>
    export default {
        name: 'AutoLoad'
    }
</script>

<style scoped>

</style>