<template>
    <div class="injector-enlarged-panel-wrapper">
        <div v-for="(item, index) in items" class="img-text-row">
            <component :is="item.icon"  class="svg-icon-component"></component>
            <span style="white-space: pre-line">{{item.description}}</span>
        </div>
    </div>
</template>

<script>
    import plusMinusIcon from "@/components/injectors/EnlargedPanel/icons/PlusMinusAuto"
    import autoLoad from "@/components/injectors/EnlargedPanel/icons/AutoLoad"
    import fillAuto from "@/components/injectors/EnlargedPanel/icons/FillAuto"

    export default {
        name: "AutoLoadMode",
        data() {
            return {
                items: [
                    {
                        icon: autoLoad,
                        description: 'Кнопка "Autoload" активирует кнопки наполнения шприц-колб в авто-режиме. \nТакже на дисплее при автозаполнении отображаются объемы жидкостей, установленные в протоколе на рабочей станции.'
                    },
                    {
                        icon: plusMinusIcon,
                        description: 'Настройка требуемого объема наполнения, когда нам нужно увеличить или уменьшить запрограммированный объем (с малым шагом увеличения и уменьшения в 5 мл).'
                    },
                    {
                        icon: fillAuto,
                        description: 'Кнопки наполнения\n' +
                            'Fill А - Контраст (зеленая)\n' +
                            'Fill B - Физраствор (синяя)\n'
                    }
                ]
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "@/scss/components/injector-enlarged-panel-wrappers";


</style>