<template>
    <div>
        <svg width="100%" height="100%" viewBox="0 0 16 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <text id="text296868-2" fill="black" xml:space="preserve" style="white-space: pre"
              font-family="Roboto" font-size="2.5" font-weight="bold" letter-spacing="0em"><tspan x="4" y="1">FILL A</tspan></text>

        <path d="M15.7255 21.6367C15.7255 23.9633 12.2058 25.852 7.86177 25.852C3.51972 25.852 0 23.9633 0 21.6367C0 19.3082 3.51972 17.4213 7.86177 17.4213C12.2058 17.4213 15.7255 19.3082 15.7255 21.6367Z"
              fill="#00429A"/>
        <path d="M15.7196 21.7608C15.5927 24.0307 12.1257 25.8484 7.86177 25.8484C3.5998 25.8484 0.130849 24.0307 0.00584159 21.7626C0.00193512 21.8051 0 21.8459 0 21.8885C0 24.2169 3.51972 26.1038 7.86177 26.1038C12.2058 26.1038 15.7255 24.2169 15.7255 21.8885C15.7255 21.8459 15.7216 21.8034 15.7196 21.7608Z"
              fill="#00426F"/>
        <path d="M7.86177 17.4196C12.155 17.4196 15.6415 19.2639 15.7216 21.5551C15.7216 21.5285 15.7255 21.5019 15.7255 21.4753C15.7255 19.1486 12.2058 17.2617 7.86177 17.2617C3.51972 17.2617 0 19.1486 0 21.4753C0 21.5019 0.00195109 21.5285 0.00390433 21.5551C0.0820338 19.2639 3.57051 17.4196 7.86177 17.4196Z"
              fill="#0073CF"/>
        <path d="M6.93507 13.9936V14H6.93375V13.9936H6.93507ZM6.93762 13.9963V13.9974H6.93471V13.9963H6.93762ZM6.93792 13.9936V13.9946H6.93471V13.9936H6.93792ZM6.94009 13.9936V14H6.93878V13.9936H6.94009ZM6.94543 13.999V14H6.94221V13.999H6.94543ZM6.94264 13.9936V14H6.94132V13.9936H6.94264ZM6.9503 13.999V14H6.94708V13.999H6.9503ZM6.94751 13.9936V14H6.94619V13.9936H6.94751ZM6.9558 13.9972H6.95415L6.95414 13.9963H6.95552C6.95577 13.9963 6.95597 13.9963 6.95612 13.9962C6.95628 13.9961 6.95639 13.996 6.95647 13.9959C6.95655 13.9958 6.95659 13.9956 6.95659 13.9955C6.95659 13.9953 6.95655 13.9951 6.95647 13.995C6.9564 13.9949 6.95628 13.9948 6.95612 13.9947C6.95597 13.9947 6.95577 13.9946 6.95552 13.9946H6.95461V14H6.95329V13.9936H6.95552C6.9559 13.9936 6.95623 13.9936 6.95652 13.9937C6.95682 13.9938 6.95707 13.9939 6.95727 13.994C6.95748 13.9942 6.95763 13.9944 6.95774 13.9946C6.95785 13.9948 6.9579 13.9951 6.9579 13.9953C6.9579 13.9956 6.95784 13.9959 6.95772 13.9961C6.95761 13.9963 6.95742 13.9965 6.95717 13.9966C6.95691 13.9968 6.95658 13.9968 6.95618 13.9969L6.9558 13.9972ZM6.95574 14H6.95379L6.95431 13.999H6.95574C6.95597 13.999 6.95616 13.9989 6.9563 13.9989C6.95645 13.9988 6.95656 13.9987 6.95663 13.9986C6.9567 13.9984 6.95674 13.9983 6.95674 13.9981C6.95674 13.9979 6.9567 13.9978 6.95664 13.9976C6.95658 13.9975 6.95648 13.9974 6.95634 13.9973C6.9562 13.9972 6.95602 13.9972 6.9558 13.9972H6.95453L6.95454 13.9963H6.95612L6.95642 13.9966C6.95681 13.9966 6.95712 13.9967 6.95736 13.9969C6.9576 13.997 6.95777 13.9972 6.95788 13.9974C6.95799 13.9976 6.95805 13.9979 6.95805 13.9981C6.95805 13.9985 6.95796 13.9989 6.95778 13.9992C6.9576 13.9994 6.95734 13.9996 6.95699 13.9998C6.95665 13.9999 6.95623 14 6.95574 14Z"
              fill="black"/>
        <text id="text296868-2" fill="black" xml:space="preserve" style="white-space: pre"
              font-family="Roboto" font-size="2.5" font-weight="bold" letter-spacing="0em"><tspan x="4" y="16">FILL B</tspan></text>
        <path d="M16.1816 6.61894C16.1816 8.95448 12.6619 10.8484 8.31982 10.8484C3.97582 10.8484 0.456085 8.95448 0.456085 6.61894C0.456085 4.28339 3.97582 2.39119 8.31982 2.39119C12.6619 2.39119 16.1816 4.28339 16.1816 6.61894Z"
              fill="#487C23"/>
        <path d="M16.1757 6.76081C16.0507 9.03074 12.5818 10.8484 8.31785 10.8484C4.05589 10.8484 0.586934 9.03072 0.461927 6.76257C0.459974 6.80513 0.456085 6.84592 0.456085 6.88848C0.456085 9.21693 3.97581 11.1038 8.31785 11.1038C12.6619 11.1038 16.1816 9.21693 16.1816 6.88848C16.1816 6.84592 16.1777 6.80337 16.1757 6.76081Z"
              fill="#487023"/>
        <path d="M8.31785 2.41959C12.6111 2.41959 16.0976 4.26391 16.1777 6.55511C16.1796 6.52851 16.1816 6.50189 16.1816 6.47529C16.1816 4.14861 12.6619 2.26175 8.31785 2.26175C3.97581 2.26175 0.456085 4.14861 0.456085 6.47529C0.456085 6.50189 0.458036 6.52851 0.45999 6.55511C0.540072 4.26391 4.02659 2.41959 8.31785 2.41959Z"
              fill="#48A123"/>
        <path d="M7.00189 0.993607V1H7.00057V0.993607H7.00189ZM7.00443 0.996338V0.997365H7.00153V0.996338H7.00443ZM7.00474 0.993607V0.994639H7.00153V0.993607H7.00474ZM7.00691 0.993607V1H7.00559V0.993607H7.00691ZM7.01225 0.998973V1H7.00903V0.998973H7.01225ZM7.00946 0.993607V1H7.00814V0.993607H7.00946ZM7.01712 0.998973V1H7.0139V0.998973H7.01712ZM7.01432 0.993607V1H7.01301V0.993607H7.01432ZM7.02271 0.9947L7.02097 1H7.01957L7.02195 0.993607H7.02284L7.02271 0.9947ZM7.02415 1L7.02241 0.9947L7.02227 0.993607H7.02317L7.02556 1H7.02415ZM7.02408 0.99762V0.998652H7.0207V0.99762H7.02408Z"
              fill="black"/>
        </svg>
    </div>
</template>

<script>
    export default {
        name: 'FillAuto'
    }
</script>

<style scoped>

</style>